import React from 'react';
import GalleryCard from '../../../Components/GalleryCard';
// import Tennis from '../../../../assets/img/tennis.png';
// import Wrestling from '../../../../assets/img/wrestling.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ListLoader from '../../../../components/ListLoader';
import GalleryCardMobile from '../../../Components/GalleryCardMobile';

import { useGalleryFolders } from 'micro-site/queries/hooks';

export default function FoldersInSection({ userRole, className, sportGallery, customQuery }) {
  const match = useRouteMatch();

  // const [sport, setSport] = useState('');
  const history = useHistory();
  const folderDetails = useGalleryFolders({
    section: match.params.section,
  });

  return (
    <div className={className || 'px-4 md:px-16 pb-14 max-w-fhd mx-auto'}>
      <div className="mt-10 md:mt-16">
        {!sportGallery ? (
          <div className="flex justify-between items-center md:shadow-shadowBottom md:mb-6 md:py-2 pt-2">
            <div
              className="flex gap-4 items-center cursor-pointer"
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">
                {match?.params?.section}
              </h3>
            </div>
          </div>
        ) : null}
        <h1 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">{sportGallery}</h1>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
        {!sportGallery ? (
          <div className="mt-6 flex gap-1.5 flex-wrap justify-center">
            <ListLoader
              queryData={folderDetails}
              queryKey={'name'}
              EmptyMessage={'Stay tuned , Images and Videos will be updated shortly'}
            >
              {(item, index) => {
                return (
                  <>
                    <GalleryCard
                      key={index}
                      item={item.data}
                      redirectTo={`${match?.url}/${item.data.name.split('-').at(-1)}`}
                    />
                    <GalleryCardMobile
                      key={index}
                      item={item.data}
                      redirectTo={`${match?.url}/${item.data.name.split('-').at(-1)}`}
                    />
                  </>
                );
              }}
            </ListLoader>
          </div>
        ) : // (
        //   <div className="mt-6 main-slider">
        //     <div className="relative">
        //       <button
        //         onClick={() => {
        //           sliderRef.current && sliderRef.current.slickNext();
        //         }}
        //         className="hidden md:inline-block absolute -right-4 bg-white rounded-full h-8 w-8 shadow-lg z-10 transform -translate-y-1/2  top-1/2 cursor-pointer"
        //       >
        //         <FontAwesomeIcon icon={faAngleRight} className="text-gray-450" />
        //       </button>
        //       <button
        //         onClick={() => {
        //           sliderRef.current && sliderRef.current.slickPrev();
        //         }}
        //         className="hidden md:inline-block  absolute left-0 bg-white rounded-full h-8 w-8 shadow-lg z-10 top-1/2 cursor-pointer transform -translate-y-1/2 -translate-x-1/2"
        //       >
        //         <FontAwesomeIcon icon={faAngleLeft} className="text-gray-450" />
        //       </button>
        //       <Slider
        //         ref={sliderRef}
        //         slidesToShow={
        //           customQuery.isLoading
        //             ? 1
        //             : customQuery.data.length >= 3
        //             ? 3
        //             : customQuery.data.length
        //         }
        //         {...settings}
        //       >
        //         {customQuery.isLoading ? (
        //           <Loader />
        //         ) : (
        //           customQuery.data.map((item, index) => {
        //             return <Temp key={index} item={item}></Temp>;
        //           })
        //         )}
        //       </Slider>
        //     </div>
        //   </div>
        // )
        null}
      </div>
      {/*
      <div className="mt-10 md:mt-16">
        <Slider {...bannerSetting}>
          <img src={Banner} className="w-full h-auto" alt={''} />
          <img src={Banner} className="w-full h-auto" alt={''} />
          <img src={Banner} className="w-full h-auto" alt={''} />
        </Slider>
      </div>
      <div
        className="text-gray-750 font-semibold cursor-pointer md:shadow-shadowBottom md:mb-6 md:pt-24 md:pb-2 pt-2"
        onClick={() => {
          history.goBack();
        }}
      >
        <h3 className="text-lg md:text-2xl font-bold text-gray-750 font-poppins">
          You might also like
        </h3>
        <hr className="w-16 md:hidden mx-0 my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      </div>
      <div className="mt-6 flex gap-6">
        {alsoLike.map((item, idx) => {
          return (
            <div key={idx}>
              <AlsoLike item={item} />
            </div>
          );
        })}
      </div>
*/}
    </div>
  );
}
