import React from 'react';
import Loader from '../Loader';
const DetailItemLoader = ({ queryData, customMessage, limit, ...props }) => {
  if (queryData.isLoading)
    return (
      <div className="flex w-full justify-center bg-white items-center">
        <Loader />
      </div>
    );
  if (queryData.isError) return <h1>Error</h1>;
  if (!props.children)
    return (
      <p className="w-full text-center my-20 text-sm text-gray-750">
        {customMessage || 'This component does not have renderable child'}{' '}
      </p>
    );
  return (
    <props.children
      {...queryData}
      data={
        !limit || typeof limit !== 'number' || !Array.isArray(queryData.data)
          ? queryData.data
          : queryData?.data?.slice(0, limit)
      }
    />
  );
};

export default DetailItemLoader;
