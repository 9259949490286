import { Switch, Redirect } from 'react-router-dom';

const CustomSwitch = ({ children }) => {
  return (
    <Switch>
      {children}
      <Redirect to={'/notfoundpage'} />
    </Switch>
  );
};

export default CustomSwitch;
