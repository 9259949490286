import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faVenus } from '@fortawesome/free-solid-svg-icons';
import Tabs from '../../components/Tab/RouteBasedTabs';
import { matchPath, useLocation } from 'react-router-dom';
import banner from '../../assets/img/banner2.jpg';
import icon from '../../assets/img/logo/uttarakhanda.png';
import { getStartAndEndDate } from '../../constants/DateFunctions';
import calendarToday from '../../assets/img/calenderToday.svg';
import officeBuilding from '../../assets/img/officeBuilding.svg';
import orangeCalendarToday from '../../assets/img/orangeCalendarToday.svg';
import maleIcon from '../../assets/img/male.svg';
import feedback from '../../assets/img/feedback.svg';

const tabList =
  process.env.REACT_APP_SHOW_GALLERY_AND_ATTRACTIONS === 'true'
    ? [
        { name: 'Sports', route: 'sports' },
        { name: 'Draws and Schedules', route: 'draws-schedules' },
        { name: 'Results', route: 'results' },
        // {
        //   name: 'Attractions and Events',
        //   route: 'attractions',
        //   suffixComponent: <span className="text-red-500 ml-1">LIVE</span>,
        // },
        { name: 'Gallery', route: 'gallery' },
        { name: 'About', route: 'overview' },
      ]
    : [
        { name: 'Sports', route: 'sports' },
        { name: 'Draws and Schedules', route: 'draws-schedules' },
        { name: 'Results', route: 'results' },
        { name: 'Gallery', route: 'gallery' },
        { name: 'About', route: 'overview' },
      ];
export default function Header({
  eventName,
  city,
  startDate,
  endDate,
  img,
  variant,
  gender,
  _ageGroup,
  venue,
  time,
  hideOnRoutes,
  hideTabs,
  removePaddingOnRoutes,
  userData,
}) {
  const location = useLocation();
  const tournamentId = location.pathname.split('/')[2];

  const hide =
    Array.isArray(hideOnRoutes) &&
    hideOnRoutes.some((el) =>
      matchPath(location.pathname, {
        path: el,
        exact: true,
      })
    );
  const hidePadding =
    Array.isArray(removePaddingOnRoutes) &&
    hideOnRoutes.some((el) =>
      matchPath(location.pathname, {
        path: el,
        exact: true,
      })
    );
  if (hide) return null;
  return (
    <div className={`bg-white flex flex-col ${hidePadding ? '' : 'pb-2'} justify-end items-center`}>
      <div
        className="w-full bg-cover block pt-4 flex-col h-32 md:h-60 gap-16"
        style={{ backgroundImage: `url(${banner})` }}
      />
      <div className="md:-mt-20 mt-4 bg-white backdrop-filter md:shadow-microHeader backdrop-blur-lg px-4 md:px-5 md:pt-7 rounded-lg w-full md:w-11/12  max-w-hdplus mx-auto ">
        <div className="flex justify-between items-center md:shadow-shadowBottom pb-0 md:pb-6">
          <div className="flex gap-3 md:gap-5 md:items-center">
            <div
              className={`flex border flex-shrink-0 ${
                !variant ? 'bg-blue-950 border-gray-300' : ''
              } rounded-full w-10 h-10 md:h-12 md:w-12 items-center justify-center mt-1 md:mt-0`}
            >
              <img src={!variant ? icon : img} alt="logo" className="h-10" />
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-2">
                <h1 className="text-gray-750 text-lg md:text-2xl font-bold">{eventName}</h1>
                {/* <FontAwesomeIcon
                  icon={faShareAlt}
                  className="text-gray-650 opacity-60 text-xl cursor-pointer"
                /> */}
              </div>
              {!variant ? (
                <>
                  <p
                    data-cy="event_city_date"
                    className="flex items-center gap-2 text-sm text-gray-650 font-medium"
                  >
                    {city && (
                      <>
                        <span>{city} </span> <Dot />
                      </>
                    )}{' '}
                    <span>{getStartAndEndDate(startDate, endDate)}</span>
                  </p>
                  <a
                    className="flex  gap-1.5 items-center text-blue-350 mr-2.5 cursor-pointer mt-3"
                    target={'_blank'}
                    rel="noreferrer"
                    href={`https://form.jotform.com/SFAPLAY/sfa-uk-2022-feedback-form?sfaId=${
                      userData?.data?.message?.sfa_id
                        ? '?sfaId=' + userData?.data?.message?.sfa_id
                        : ''
                    }`}
                  >
                    <img src={feedback} className="h-4 md:h-auto" alt={''} />
                    <p className="font-bold text-xs md:text-sm">Help us improve your experience</p>
                  </a>{' '}
                </>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {gender.toLowerCase() === 'male' ? (
                    <div className="px-1 py-0.5 flex flex-row gap-2 items-center justify-center font-bold text-xxs text-blue-iris bg-blue-irisLight rounded">
                      {' '}
                      <img src={maleIcon} alt={''} />
                      {gender}
                    </div>
                  ) : (
                    <div className="px-1 py-0.5 flex flex-row gap-2 items-center justify-center font-bold text-xxs text-blue-iris bg-blue-irisLight rounded">
                      {' '}
                      <FontAwesomeIcon icon={faVenus} />
                      {gender}
                    </div>
                  )}
                  <div className="px-1 py-0.5 flex flex-row gap-2 items-center justify-center font-bold text-xxs text-pink-750 bg-pink-150 rounded">
                    <img src={calendarToday} alt={''} />
                    {'U-19'}
                  </div>

                  <div className="px-1 py-0.5 flex flex-row gap-2 items-center justify-center font-bold text-xxs text-orange-200 bg-orange-150 rounded">
                    <img src={orangeCalendarToday} alt={''} />
                    {time}
                  </div>
                  <div className="px-1 py-0.5 flex flex-row gap-2 items-center justify-center font-bold text-xxs text-blue-625 bg-blue-600 rounded">
                    <img src={officeBuilding} alt={''} />
                    {venue}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <div className="flex flex-col items-end gap-1.5">
              <p className="px-4 py-2 bg-green-450 rounded-lg text-white font-bold cursor-pointer">
                Register Now at ₹ {price}*
              </p>
              <p className="text-gray-650 opacity-60 text-sm">{remarks}</p>
            </div> */}
        </div>

        <div
          className="mx-6 mb-2 hidden items-center py-3 md:py-0 cursor-pointer"
          // onClick={() => history.push('/')}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="fa-xs text-gray-750 mr-3" />
          <p className="text-gray-750 font-bold">Arena</p>
        </div>
        {!hideTabs ? (
          <Tabs
            tabList={tabList}
            color="text-blue-350"
            border="border-blue-350"
            baseUrl={`/micro-site/${tournamentId}/landing`}
            page="micro-site"
          />
        ) : null}
      </div>
    </div>
  );
}

const Dot = () => (
  <span
    style={{
      width: '2px',
      height: '2px',
    }}
    className="bg-black rounded-full"
  ></span>
);
