import Http from 'utils/Http';
import Analytics from 'utils/analytics';

const apiEndPoints = {
  getOtp: '/sfa_online.v1.registrations.signup.send_otp',
  getMobileOtp: '/sfa_online.v1.registrations.signup.send_whatsapp_otp',
  checkOtp: '/sfa_online.v1.registrations.signup.verify_otp',
  signUp: '/sfa_online.v1.registrations.signup.create_user',
  resendOtp: '/sfa_online.v1.registrations.signup.resend_otp',
  forgotPassword: '/frappe.core.doctype.user.user.reset_password',
  updatePassword: '/frappe.core.doctype.user.user.update_password',
  leadGeneration: 'sfa_online.v1.registrations.signup.submit_lead_generation_data',
  checkPlatformInvite: '/sfa_online.v1.registrations.signup.check_platform_invite',
  verifyAutoKey: 'sfa_online.v1.profile.set_session',
  signinUser: '/sfa_online.v1.registrations.signup.login_with_otp',
};

/**
 * Sign in user with username and password
 * @param {{string, number}} object
 * @returns {object}
 */
export async function signInUserApi({ username, password }) {
  try {
    return await Http.login(username, password);
  } catch (error) {
    throw error.message;
  }
}

export async function checkPlatformInvite(email, institutionId, tournamentId, otp) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        institution: institutionId,
        tournament: tournamentId,
        otp: otp,
      }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.checkPlatformInvite, requestOptions).then(
      (res) => res.json()
    );
  } catch (error) {
    throw error.message;
  }
}

/**
 * Get profile details for logged-in user
 * @returns {object}
 */
export async function getUserProfile() {
  try {
    return await Http.fetch({
      apiEndpoint: 'sfa_online.v1.profile.get_profile',
      headers: { 'Content-Type': 'application/json' },
      method: 'get',
    });
  } catch (error) {
    throw error.message;
  }
}

/**
 * Logout the user
 * @param {Object} data object containing full_name and email for analytics
 */
export async function logOut(data) {
  await Http.logout();
  if (Analytics)
    Analytics.track('Logout', {
      full_name: data?.full_name || data?.message?.full_name,
      email: data?.email || data?.message?.email,
    });
  window.location.pathname = '/signin';
}

export async function sendOtp(email, institutionId, tournamentId, otp) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        institution: institutionId,
        tournament: tournamentId,
        otp: otp,
      }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.getOtp, requestOptions).then((res) =>
      res.json()
    );
  } catch (error) {
    throw error.message;
  }
}

export async function sendLead({
  DOB = '',
  athlete_full_name: athleteId = '',
  coach_full_name: coachName = '',
  city = '',
  country = '',
  email = '',
  gender = '',
  schoolType = '',
  phone = '',
  role = '',
  state = '',
  institution_full_name: institutionName = undefined,
  ..._test
}) {
  let newDate = '';
  if (DOB) {
    const date = DOB && DOB.split('/');
    newDate = DOB && date[2] + '-' + date[1] + '-' + date[0];
  }

  try {
    return Http.fetch({
      apiEndpoint: apiEndPoints.leadGeneration,
      method: 'POST',
      options: {
        email,
        role,
        institution_type: schoolType,
        city,
        full_name: role !== 'coach' ? athleteId : coachName,
        country,
        date_of_birth:
          new Date(newDate).toJSON() && new Date(newDate).toJSON().split('.').shift() + 'Z',
        gender: gender,
        mobile_number: phone,
        state,
        institution_name: institutionName,
      },
    });
  } catch (error) {
    console.log(error.message);
  }
}
export async function sendMobileOtp({ name, phone, institution, tournament, otp }) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name,
        phone,
        institution,
        tournament,
        otp,
      }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.getMobileOtp, requestOptions).then((res) =>
      res.json()
    );
  } catch (error) {
    throw error.message;
  }
}

export async function sendMobileOtpexisitedUser({ name, phone, institution, tournament, otp }) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name,
        phone,
        institution,
        tournament,
        otp,
      }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.getMobileOtp, requestOptions).then((res) =>
      res.json()
    );
  } catch (error) {
    throw error.message;
  }
}

export async function forgotPasswordApi(email) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: email,
      }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.forgotPassword, requestOptions).then((res) =>
      res.json()
    );
  } catch (error) {
    throw error.message;
  }
}

export async function updatePasswordApi({ password, key }) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        new_password: password,
        key: key,
      }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.updatePassword, requestOptions).then((res) =>
      res.json()
    );
  } catch (error) {
    throw error.message;
  }
}

export async function verifyOtp({ email, otp, tournament, institution }) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: institution
        ? JSON.stringify({
            email,
            otp,
            tournament: tournament,
            institution: institution,
          })
        : JSON.stringify({
            email,
            otp,
          }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.checkOtp, requestOptions).then((res) =>
      res.json()
    );
  } catch (error) {
    throw error.message;
  }
}

export async function verifyMobileOtp({ phone, otp, tournament, institution }) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: institution
        ? JSON.stringify({
            phone,
            otp,
            otp_type: 'phone',
            tournament: tournament,
            institution: institution,
          })
        : JSON.stringify({
            phone,
            otp,
            otp_type: 'phone',
          }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.checkOtp, requestOptions).then((res) =>
      res.json()
    );
  } catch (error) {
    throw error.message;
  }
}

export async function resendOtp({ email, _otp }) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
      }),
    };

    return await fetch(Http.baseUrl + apiEndPoints.resendOtp, requestOptions).then((res) =>
      res.json()
    );
  } catch (error) {
    throw error.message;
  }
}
export async function signInService({ formFields, institution })  {
  console.log(formFields)

  try {
    let name;
    if (formFields.role === 'athlete') name = formFields.athlete_full_name;
    if (formFields.role === 'institution') name = formFields.institution_full_name;
    if (formFields.role === 'coach') name = formFields.coach_full_name;
    // console.log('formFields', formFields);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: institution
        ? JSON.stringify({
            role: formFields.role,
            full_name: name,
            password: formFields.password,
            otp: formFields.otp,
            email: formFields.email,
            phone: formFields.phone,
            DOB:
              formFields.DOB && formFields.DOB !== ''
                ? new Date(formFields.DOB).toJSON().split('.').shift() + 'Z'
                : '',
            gender: formFields.gender,
            country: formFields.country,
            state: formFields.state,
            city: formFields.city,
            schoolType: formFields.schoolType,
            institution,
            school: formFields.school,
          })
        : JSON.stringify({
            role: formFields.role,
            full_name: name,
            password: formFields.password,
            otp: formFields.otp,
            email: formFields.email,
            phone: formFields.phone,
            DOB:
              formFields.DOB && formFields.DOB !== ''
                ? new Date(formFields.DOB).toJSON().split('.').shift() + 'Z'
                : '',
            gender: formFields.gender,
            country: formFields.country,
            state: formFields.state,
            city: formFields.city,
            schoolType: formFields.schoolType,
            school: formFields.school,
          }),
    };
    return await fetch(Http.baseUrl + apiEndPoints.signinUser, requestOptions).then((response) =>
      response.json()
    );
  } catch (e) {
    console.error('error-', e);
  }
  
  
}

export async function signUpService(data) {
  const datas = data.data
  console.log(datas)
  try {
    // let newDate = '';
    // if (formFields.DOB && formFields.DOB !== '') {
    //   const date = formFields.DOB.split('/');
    //   newDate = date[2] + '-' + date[1] + '-' + date[0];
    // }
    let name;
    if (datas.role === 'athlete') name = datas.athlete_full_name;
    if (datas.role === 'institution') name = datas.institution_full_name;
    if (datas.role === 'coach') name = datas.coach_full_name;
    // console.log(institution);
    // console.log('formFields', formFields);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: institution
      //   ? JSON.stringify({
      //       role: formFields.role,
      //       full_name: name,
      //       password: formFields.password,
      //       otp: formFields.otp,
      //       email: formFields.email,
      //       phone: formFields.phone,
      //       DOB:
      //         formFields.DOB && formFields.DOB !== ''
      //           ? new Date(formFields.DOB).toJSON().split('.').shift() + 'Z'
      //           : '',
      //       gender: formFields.gender,
      //       country: formFields.country,
      //       state: formFields.state,
      //       city: formFields.city,
      //       schoolType: formFields.schoolType,
      //       institution,
      //       school: formFields.school,
      //     })
      //   : 
      body: JSON.stringify({
            role: datas.role,
            full_name: name,
            password: datas.password,
            otp: datas.otp,
            email: datas.email,
            phone: datas.phone,
            DOB:
            datas.DOB && datas.DOB !== ''
                ? new Date(datas.DOB).toJSON().split('.').shift() + 'Z'
                : '',
            gender: datas.gender,
            country: datas.country,
            state: datas.states,
            city: datas.city,
            schoolType: datas.schoolType,
            school: datas.school,
          }),
    };
    return await fetch(Http.baseUrl + apiEndPoints.signUp, requestOptions).then((response) =>
      response.json()
    );
  } catch (e) {
    console.error('error-', e);
  }
}
export async function checkValidPlatformInvite({ institutionId, tournamentId, otp }) {
  try {
    const res = await fetch(Http.baseUrl + apiEndPoints.checkPlatformInvite, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        institution: institutionId,
        tournament: tournamentId,
        otp,
      }),
    });
    return await res.json();
  } catch (e) {
    console.error(e.message);
  }
}

export async function verifyAutoKey({ key }) {
  try {
    return await Http.fetch({
      apiEndpoint: apiEndPoints.verifyAutoKey + `?key=${key}`,
      method: 'POST',
    }).then((res) => res);
  } catch (error) {
    throw error.message;
  }
}
