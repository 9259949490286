import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import Landing from './Landing';
// import Header from './Components/Header';
import IndividualEvent from './Landing/Gallery/components/IndividualEvent';
import SportEventList from './Landing/Sports/SportEventList';
// import microSiteLogo from '../assets/img/logo/uttarakhand_logo.jpg';
// import microSiteBg from '../assets/img/uttarakhand_desktop.jpg';
import Navbar from './Components/Navbar';
// import DetailItemLoader from 'components/DetailItemLoader';
// import { useTournamentDetails } from './queries/hooks';
import ScrollToTop from './Components/ScrollToTop';
import FoldersInSection from './Landing/Gallery/components/FoldersInSection';
import Footer from './Components/Footer';
import CustomSwitch from 'components/routes/CustomSwitch';
// import { useUserProfile } from 'profiles/queries/profileHooks';
export default function MicroSite() {
  const match = useRouteMatch('/micro-site/:tournament');
  // const tournamentDetailsQuery = useTournamentDetails({
  //   tournament_id: location.pathname.split('/')[2],
  // });
  // const userData = useUserProfile();
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div>
        <div>
          <Navbar fromMicroSite />
          {/* <DetailItemLoader queryData={tournamentDetailsQuery} queryKey="id">
            {({ data }) => {
              return (
                <Header
                  userData={userData}
                  eventName={data?.name}
                  city={data?.city}
                  eventImage={microSiteLogo}
                  banner={microSiteBg}
                  // price={data.price}
                  remarks={`This is one time tournament fees`}
                  // remarks={data.remarks}
                  startDate={data?.start_date}
                  endDate={data?.end_date}
                  hideOnRoutes={[
                    `${match.url}/landing/draws-schedules`,
                    `${match.url}/landing/sports/:sport`,
                  ]}
                  removePaddingOnRoutes={[`${match.url}/landing/overview`]}
                />
              );
            }}
          </DetailItemLoader> */}
        </div>
        <CustomSwitch>
          <Route exact path={`${match?.url}`}>
            <Redirect to={`${match?.url}/landing`} />
          </Route>
          <Route exact path={`${match?.url}/landing`}>
            <Redirect to={`${match?.url}/landing/overview`} />
          </Route>
          <Route exact path={`${match?.url}/landing/:tab`} component={Landing} />
          <Route exact path={`${match?.url}/landing/sports/:sport`} component={SportEventList} />
          <Route
            exact
            path={`${match?.url}/landing/gallery/:section/:folder`}
            component={IndividualEvent}
          />
          {/*
          <Route
            exact
            path={`${match?.url}/landing/gallery/video/:type`}
            render={(props) => <IndividualEvent withVideo {...props} />}
          />
*/}

          <Route
            exact
            path={`${match?.url}/landing/gallery/:section`}
            component={FoldersInSection}
          />

          {/* Default route */}
          <Route path="*">
            <Redirect to="/micro-site/landing" />
          </Route>
        </CustomSwitch>
        <ScrollToTop />
      </div>
      <Footer />
    </div>
  );
}
