import React, { useState, Fragment } from 'react';
import sfaLogo from '../../assets/img/logo2024.png';

import { Link, NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import menuIcon from '../../assets/img/hamburger.svg';
import crossIcon from '../../assets/img/sfa-cross.svg';

import { useUserProfile } from 'profiles/queries/profileHooks';
import { Transition, Popover } from '@headlessui/react';
import { logOut } from 'authentication/services/authentication';
import signOut from '../../assets/img/log-out.svg';
import ProfileIcon from '../../assets/img/user.svg';
import settingsIcon from '../../assets/img/settings.svg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ fromMicroSite, fromDigitalScoring, invisibleLinks }) => {
  const userProfileQuery = useUserProfile(undefined, !invisibleLinks);
  const match = useRouteMatch();
  const [openNavbar, setOpenNavbar] = useState(false);
  const [close2, setClose2] = useState();
  // const [profileOpen, setProfileOpen] = useState(false);
  // const isLg = useBreakPoint('lg');

  const history = useHistory();

  const MicroSiteroutes = [
    // { name: 'Home', route: 'overview' },
    // { name: 'Sports', route: 'sports' },
    // { name: 'Draws and Schedules', route: 'draws-schedules' },
    // // { name: 'Draws', route: 'draws-schedules' },
    // { name: 'Result', route: 'results' },
    // { name: 'Attractions', route: 'attractions' },
    // // { name: 'Venues', route: 'venues' },
    // { name: 'Gallery', route: 'gallery' },
    // // { name: 'Media', route: 'media' },
    // { name: 'e-Book Info Guide', route: '%22How%20To%22%20Guide' },
    // { name: 'Home', route: 'overview' },
    // { name: 'Sports', route: 'sports' },
    // { name: 'Draws and Schedules', route: 'draws-schedules' },
    // { name: 'Results', route: 'results' },
    // { name: 'Gallery', route: 'gallery' },
  ];
  const DigitalScoringRoutes = [
    // { name: 'Home', route: '/micro-site/221/landing/overview', ignoreBaseRoute: true },
    // { name: 'Sports', route: '/micro-site/221/landing/sports', ignoreBaseRoute: true },
    // {
    //   name: 'Draws and Schedules',
    //   route: '/micro-site/221/landing/draws-schedules',
    //   ignoreBaseRoute: true,
    // },
    // { name: 'Draws', route: '/micro-site/221/landing/draws-schedules' , ignoreBaseRoute: true},
    // { name: 'Results', route: '/micro-site/221/landing/results', ignoreBaseRoute: true },
    // { name: 'Attractions', route: '/micro-site/221/landing/attractions', ignoreBaseRoute: true },
    // { name: 'Gallery', route: '/micro-site/221/landing/gallery', ignoreBaseRoute: true },
    // { name: 'Venues', route: '/micro-site/221/landing/venues' , ignoreBaseRoute: true},
    // { name: 'Media', route: '/micro-site/221/landing/media', ignoreBaseRoute: true },
  ];

  const ProfileRoutes = [
    // {
    //   name: 'Profile',
    //   route: `/${userProfileQuery?.data?.message?.primary_role}/profiles/profile`,
    //   ignoreBaseRoute: true,
    // },
    // {
    //   name: 'Account Information',
    //   route: `/${userProfileQuery?.data?.message?.primary_role}/profiles/account-details`,
    //   ignoreBaseRoute: true,
    // },
    // {
    //   name: 'Draws and Schedules',
    //   route: `/micro-site/221/landing/draws-schedules`,
    //   ignoreBaseRoute: true,
    // },
    // {
    //   name: 'Sports',
    //   route: `/micro-site/221/landing/sports`,
    //   ignoreBaseRoute: true,
    // },
    // {
    //   name: 'Schedule',
    //   route: `/micro-site/221/landing/schedules`,
    //   ignoreBaseRoute: true,
    // },
    // {
    //   name: 'Draws & Schedule',
    //   route: `/micro-site/221/landing/draws-schedules`,
    //   ignoreBaseRoute: true,
    // },
    // {
    //   name: 'Results',
    //   route: `/micro-site/221/landing/results`,
    //   ignoreBaseRoute: true,
    // },
    // {
    //   name: 'Attractions',
    //   route: `/micro-site/221/landing/attractions`,
    //   ignoreBaseRoute: true,
    // },
    // { name: 'Gallery', route: `/micro-site/221/landing/gallery`, ignoreBaseRoute: true },
    // { name: 'Venues', route: `/micro-site/221/landing/venues`, ignoreBaseRoute: true },
    // { name: 'Media', route: '/micro-site/221/landing/media', ignoreBaseRoute: true },
  ];
  const actualRoutes = fromMicroSite
    ? MicroSiteroutes
    : fromDigitalScoring
    ? DigitalScoringRoutes
    : ProfileRoutes;

  return (
    <>
      <div className={`bg-blue-950 top-0 z-20 ${fromMicroSite ? 'fixed w-full' : 'sticky'}`}>
        <div
          className={
            'w-full h-12 lg:h-14 md:px-4 xl:px-16 flex justify-between shadow items-center font-montserrat'
          }
        >
          <Link
            to={
              fromMicroSite
                ? `${match.url}/landing/overview`
                : `/${userProfileQuery?.data?.message?.primary_role}/profiles/profile`
            }
          >
            <div className="flex items-center">
              {/* Back arrow for all views */}
              <span className="mr-2 text-xl text-black">{'←'}</span>

              {/* Desktop view: logo */}
              <img src={sfaLogo} className={'hidden lg:inline-block cursor-pointer h-2 md:h-7'} alt={''} />

              {/* Mobile view: logo */}
              <img src={sfaLogo} className={'lg:hidden cursor-pointer h-7 md:h-5'} alt={''} />
            </div>


          </Link>
          <div className={'items-center lg:flex hidden gap-4'}>
            {fromMicroSite || fromDigitalScoring ? (
              <div className={'flex items-center  xl:mr-16 font-medium'}>
                {actualRoutes.map((route) => (
                  <div key={route.name} className={'2xl:mr-8 md:mr-6 mr-4 truncate'}>
                    <NavLink
                      activeClassName="text-orange-925"
                      to={
                        route.route === '%22How%20To%22%20Guide'
                          ? 'gallery/%22How%20To%22%20Guide'
                          : !route.ignoreBaseRoute
                          ? `${match.url}/landing/${route.route}`
                          : route.route
                      }
                      className={'text-white  text-sm ignore-style'}
                    >
                      {route.name}
                    </NavLink>
                  </div>
                ))}
                {/* <div
                  className="text-white text-sm ignore-style cursor-pointer"
                  onClick={() => {
                    history.push(`/micro-site/221/landing/overview`);
                    setTimeout(() => {
                      scrollTo(0, document.body.scrollHeight - 1000);
                    }, 2000);
                  }}
                >
                  Partners
                </div> */}
              </div>
            ) : null}
            {userProfileQuery.isSuccess ? (
              <>
                <Popover className="relative lg:mr-3.5">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`
          ${open ? '' : 'text-opacity-90'}
          text-white group bg-orange-700 px-2 py-1.5 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span
                          data-cy="user_icon"
                          onClick={
                            open
                              ? () => {
                                  close2 ? setClose2(false) : setClose2(true);
                                }
                              : () => setClose2(false)
                          }
                        >
                          <div
                            src={userProfileQuery?.data?.message?.image}
                            alt="icon"
                            style={{
                              borderRadius: '50%',

                              background: `url('${userProfileQuery?.data?.message?.image}')`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                            }}
                            className="w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10"
                          />
                        </span>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          className={`absolute z-10 w-60 lg:w-72 px-4 transform -translate-x-1/2 -left-8 md:-left-20 lg:-left-24 sm:px-0 lg:max-w-3xl ${
                            close2 ? 'hidden' : 'block'
                          }`}
                        >
                          <div className="overflow-hidden rounded-lg shadow-lg">
                            <div
                              className="relative flex-col gap-8 bg-white p-4"
                              onClick={() => setClose2(true)}
                            >
                              <div
                                onClick={() =>
                                  history.push(
                                    `/${userProfileQuery?.data?.message?.primary_role}/profiles/profile`
                                  )
                                }
                                className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                              >
                                <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                                  <img
                                    src={ProfileIcon}
                                    className="mt-1"
                                    alt="logout"
                                    height="16px"
                                    width="16px"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p
                                    data-cy="my_account_icon"
                                    className="text-sm mt-1  text-gray-900"
                                  >
                                    My Profile
                                  </p>
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  history.push(
                                    `/${userProfileQuery?.data?.message?.primary_role}/profiles/account-details`
                                  )
                                }
                                className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                              >
                                <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                                  <img
                                    src={settingsIcon}
                                    className="mt-1"
                                    alt="logout"
                                    height="16px"
                                    width="16px"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p
                                    data-cy="my_account_icon"
                                    className="text-sm mt-1  text-gray-900"
                                  >
                                    My Account
                                  </p>
                                </div>
                              </div>
                              <div
                                onClick={() => logOut(userProfileQuery?.data)}
                                className={`cursor-pointer flex items-center transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                              >
                                <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                                  <img
                                    src={signOut}
                                    className="mt-1"
                                    alt="logout"
                                    height="16px"
                                    width="16px"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm mt-1 font-medium text-blue-kheloBlue">
                                    Logout
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </>
            ) : (
              <button
                className={'bg-orange-925 rounded-lg px-3 py-1 xl:mr-8'}
                onClick={() => history.push('/signin')}
              >
                <span className={'text-sm font-roboto font-bold text-white truncate'}>Sign In</span>
              </button>
            )}
          </div>

          {!invisibleLinks ? (
            <img
              src={menuIcon}
              className="w-8 mr-2 lg:hidden"
              onClick={() => setOpenNavbar(true)}
            />
          ) : null}
        </div>
      </div>
      <div
        className={`bg-white min-h-screen max-h-screen w-full fixed overflow-y-scroll top-0 z-40 lg:hidden ${
          !openNavbar ? '-right-full' : 'right-0'
        } transition-all duration-500`}
      >
        <div className="p-7 h-full">
          <div className="flex justify-between mb-8 overflow-y-auto">
            <div
              className="flex gap-4 items-center px-5 overflow-y-auto"
              onClick={() => {
                history.push(`/${userProfileQuery?.data?.message?.primary_role}/profiles/profile`);
                setOpenNavbar(false);
              }}
            >
              <div
                className="h-12 w-12 rounded-full flex-shrink-0 inline-block"
                style={{
                  backgroundImage: `url('${
                    userProfileQuery?.data?.message?.image || ProfileIcon
                  }')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              ></div>
              <h1 className="text-sm font-bold text-blue-900 font-brand ">
                {(userProfileQuery?.isSuccess && userProfileQuery?.data?.message?.full_name) ||
                  'Guest User'}
              </h1>
            </div>
            <img src={crossIcon} onClick={() => setOpenNavbar(false)} />
          </div>

          <div className=" bg-gray-900 h-px my-8"></div>
          <div className=" flex flex-col gap-8">
            {actualRoutes.map((route, i) =>
              i ? (
                <Link
                  onClick={() => setOpenNavbar(false)}
                  key={i}
                  to={
                    route.route === '%22How%20To%22%20Guide'
                      ? 'gallery/%22How%20To%22%20Guide'
                      : !route.ignoreBaseRoute
                      ? `${match.url}/landing/${route.route}`
                      : route.route
                  }
                  className="text-black text-l  font-bold font-brand ignore-style"
                >
                  {route.name}
                </Link>
              ) : (
                <>
                  <Link
                    onClick={() => setOpenNavbar(false)}
                    key={i}
                    to={
                      !route.ignoreBaseRoute ? `${match.url}/landing/${route.route}` : route.route
                    }
                    className="text-black text-l  font-bold font-brand ignore-style"
                  >
                    {route.name}
                  </Link>
                  {/* {userProfileQuery.isSuccess && (
                    <>
                      <div
                        className="text-secondary-khelo text-l  font-bold font-brand "
                        onClick={() =>
                          setProfileOpen((prevData) => {
                            return !prevData;
                          })
                        }
                      >
                        Profile
                        <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
                      </div>
                      <div className={`ml-3 -mt-5 ${profileOpen ? 'block' : 'hidden'}`}>
                        <div
                          className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                          onClick={() => {
                            setOpenNavbar(false);
                            setProfileOpen((prevData) => {
                              return !prevData;
                            });
                            history.push(
                              `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/match-fixtures-results`
                            );
                          }}
                        >
                          Matches
                        </div>
                        <div
                          className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                          onClick={() => {
                            setOpenNavbar(false);
                            setProfileOpen((prevData) => {
                              return !prevData;
                            });
                            history.push(
                              `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/accomodation`
                            );
                          }}
                        >
                          Arrival/Stay
                        </div>
                        <div
                          className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                          onClick={() => {
                            setOpenNavbar(false);
                            setProfileOpen((prevData) => {
                              return !prevData;
                            });
                            history.push(
                              `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/transport`
                            );
                          }}
                        >
                          Food/Travel
                        </div>
                        <div
                          className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                          onClick={() => {
                            setOpenNavbar(false);
                            setProfileOpen((prevData) => {
                              return !prevData;
                            });
                            history.push(
                              `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/accreditation`
                            );
                          }}
                        >
                          Accred & Kit
                        </div>

                        {userProfileQuery?.data?.message?.primary_role === 'coach' && (
                          <>
                            <div
                              className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                              onClick={() => {
                                setOpenNavbar(false);
                                setProfileOpen((prevData) => {
                                  return !prevData;
                                });
                                history.push(
                                  `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/contingents`
                                );
                              }}
                            >
                              Contingents
                            </div>
                            <div
                              className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                              onClick={() => {
                                setOpenNavbar(false);
                                setProfileOpen((prevData) => {
                                  return !prevData;
                                });
                                history.push(
                                  `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/events`
                                );
                              }}
                            >
                              My Teams
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )} */}
                </>
              )
            )}

            {userProfileQuery.isSuccess ? (
              <div
                className="text-secondary-khelo text-l  font-bold font-brand "
                onClick={() => logOut(userProfileQuery?.data)}
              >
                Log Out
              </div>
            ) : (
              <div
                className="text-secondary-khelo text-l  font-bold font-brand "
                onClick={() => history.push('/signin')}
              >
                Sign In
              </div>
            )}
          </div>
          <div className=" bg-gray-900 h-px my-8"></div>
          <div className="flex gap-4 justify-center font-medium">
            {/* <a
              href="https://web.kheloindia.gov.in/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="text-blue-kheloBlue text-xs underline font-brand "
            >
              Privacy Policy
            </a> */}
            {/* <div className="text-blue-kheloBlue text-xs underline font-brand ">
              Terms & Conditions
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
