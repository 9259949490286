import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

const ScrollToTop = () => {
  const [state, setState] = useState(false);
  useEffect(() => {
    function scrollHandler() {
      if (window.scrollY > 100) {
        setState(true);
      } else {
        setState(false);
      }
    }
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);
  if (!state) return null;
  return (
    <button
      className="bg-blue-950 w-12 h-12 fixed bottom-0 right-0 mb-2 mr-2 text-white rounded-full cursor-pointer border-1 border-white"
      onClick={() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    >
      <FontAwesomeIcon icon={faAngleUp} />
    </button>
  );
};

export default ScrollToTop;
