import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Listbox } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const Index = ({ hideBorder, lastElement, id, selectedValue, setSelectedValue, placeholder }) => {
  const border = () => {
    if (hideBorder === true && id === 0) return 'rounded-l-lg border-t-1 border-b-1 border-l-1';
    if (hideBorder === true && id === lastElement)
      return 'rounded-r-lg border-t-1 border-l-1 border-b-1 border-r-1';
    if (hideBorder === true && typeof id === 'number') return 'border-1 border-r-0';
    if (hideBorder === undefined && id === undefined) return 'rounded-lg border-1';
  };

  const dateInput = useRef();
  return (
    <div className={'top-16'}>
      <Listbox value={selectedValue} onChange={(value) => setSelectedValue(value)}>
        <div className={'relative mt-1'}>
          <Listbox.Button
            className={`relative w-full py-2 pl-3 pr-10 text-left bg-white ${border()}
            border-gray-350 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75
            focus-visible:ring-white focus-visible:ring-offset-gray-300 focus-visible:ring-offset-2 focus-visible:border-gray-500
            sm:text-sm`}
            onClick={() => {
              dateInput.current.click();
            }}
          >
            <span className="block truncate text-sm text-gray-450">
              {!selectedValue ? placeholder : selectedValue}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FontAwesomeIcon icon={faAngleDown} className="text-gray-450" />
            </span>
          </Listbox.Button>
        </div>
      </Listbox>
    </div>
  );
};

Index.propTypes = {
  hideBorder: PropTypes.bool,
  lastElement: PropTypes.bool,
  id: PropTypes.number,
  selectedValue: PropTypes.string,
  setSelectedValue: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Index;
