import ListLoader from 'components/ListLoader';
import Loader from 'components/Loader';
import React from 'react';
import gold from 'assets/img/gold.png';
import silver from 'assets/img/silver.png';
import bronze from 'assets/img/bronze.png';

export default function RecentWinnersCarousel({ ReactQueryObject, fromResult = false }) {
  if (ReactQueryObject.isLoading) return <Loader />;
  else
    return (
      <div
        className={`flex justify-start gap-2 ${
          ReactQueryObject.data?.length > 0 ? 'bg-purple-950' : ''
        } text-white px-6 py-8 rounded-2xl`}
      >
        {ReactQueryObject.data?.length > 0 ? (
          <ListLoader queryData={ReactQueryObject} queryKey="id">
            {({ item }) => {
              return (
                <div className="w-36 flex flex-col" key={item.athlete.sfa_id}>
                  <div className="flex flex-col items-center  pb-1">
                    <img
                      src={item.athlete.image}
                      alt="dp"
                      className="w-24 h-24 rounded-full overflow-hidden border-4 border-white relative"
                    />
                    <img
                      src={item.medal === 'Gold' ? gold : item.medal === 'Silver' ? silver : bronze}
                      alt="medalPlaceholder"
                      className="absolute w-auto h-8 mt-20 bg-white p-1 rounded-full"
                    />
                  </div>
                  <div style={{ flex: '1 1 auto' }}>
                    <div className="flex flex-col justify-between h-full ">
                      {' '}
                      <div>
                        <p className="text-xs mt-6 font-bold text-center">{item.athlete.name}</p>
                        <p className="text-xxs mt-0.5 opacity-60 text-center">
                          {item.athlete.institution}
                        </p>
                      </div>
                      <div className="border-t-1 border-primary-50 pt-1">
                        <div className="flex flex-col items-center py-1 shadow-shadowBottomWhite">
                          <p className="text-xxs opacity-60 text-center">
                            {item.sport_event.sport}{' '}
                            {item?.sport_event?.sub_category &&
                              `- ${item?.sport_event?.sub_category}`}
                          </p>
                        </div>
                        <div className="flex flex-col items-center pt-1">
                          <p className="text-xxs opacity-60">
                            {item.sport_event.gender} / {item.sport_event.age_group}
                          </p>
                        </div>
                      </div>
                    </div>{' '}
                  </div>
                </div>
              );
            }}
          </ListLoader>
        ) : fromResult ? (
          <div className="bg-purple-950 text-white text-center rounded-lg py-8 w-full">
            No Winners till now
          </div>
        ) : null}
      </div>
    );
}
