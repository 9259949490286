import React, { useEffect, useState } from 'react';
import FiltersWrapper from 'components/FiltersWrapper';
import Card from '../../Components/EventCard';
// import basketball from '../../../assets/img/basketball3.png';
import { useTournamentSportList } from '../../queries/hooks';
// import ListLoader from 'components/ListLoader';
import Loader from 'components/Loader';
import SelectList from 'components/select-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchIcon from '../../../assets/img/search_icon.svg';
import FilterIcon from '../../../assets/img/Filters.svg';

const categoryList = ['one', 'two', 'three'];
export default function Index() {
  const [searchedSport, setSearchedSport] = useState('');
  const [sportsToShow, setSportsToShow] = useState([]);
  const [sport, setSport] = useState(null);
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [filterPopup, setFilterPopup] = useState(false);

  const filterPopupHandler = () => {
    setFilterPopup(!filterPopup);
  };

  const clearFilterHandler = () => {
    setSearchedSport('All');
    setFilterPopup(false);
  };

  const data = [
    {
      selectedValue: sport,
      setSelectedValue: setSport,
      placeholder: 'Sport',
      listData: categoryList,
    },
    {
      selectedValue: type,
      setSelectedValue: setType,
      placeholder: 'Date',
      listData: categoryList,
    },
    {
      selectedValue: status,
      setSelectedValue: setStatus,
      placeholder: 'Status',
      listData: categoryList,
    },
  ];

  const sportData = useTournamentSportList({
    tournamentId: location.pathname.split('/')[2],
  });

  const getSportsSelectorList = () => {
    return sportData?.data?.reduce((acc, el) => [...acc, el.title], ['All']);
  };

  useEffect(() => {
    if (sportData.isFetched)
      // console.log(sportData?.data);
      setSportsToShow(
        sportData?.data?.filter(
          (item) => searchedSport === 'All' || item.title.includes(searchedSport)
        )
      );
    // setSportsToShow(sportData?.data?.filter(item => item.sport.includes(searchedSport)));
  }, [searchedSport, sportData.isLoading]);

  // console.log(sportsToShow);
  // console.log(sportData);
  return (
    <div className="md:mt-8 pt-4 md:pt-0 px-4 md:px-16 bg-white pb-44 mx-auto max-w-fhd">
      {/* {<h1 className={`my-3 font-bold text-lg`}>Sport List</h1>} */}
      <FiltersWrapper filters={data} />

      <div
        className={`fixed overflow-y-auto shadow-footerPopUp h-3/4 rounded-t-xl w-full bg-white flex flex-col justify-between px-4 py-6 bottom-0 right-0 left-0 z-20 ${
          filterPopup ? 'block' : 'hidden'
        } `}
      >
        <div>
          <div className="flex justify-between">
            <h3 className="text-base font-bold gray-text-750">Filters</h3>
          </div>

          <div className="mt-8">
            <div className="mb-4 w-full">
              <p className="text-sm mb-1 ">Sport</p>
              <SelectList
                dataCy="sport_selector"
                selectedValue={searchedSport}
                setSelectedValue={setSearchedSport}
                placeholder="Sport"
                listData={getSportsSelectorList()}
                fullWidth={true}
                filterClose={true}
                setFilterPopup={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 items-center text-sm md:text-base mt-auto sticky w-full bottom-0 py-2 bg-white">
          <button
            onClick={() => setFilterPopup(false)}
            className="btn btn-primary btn-md btn-block  bg-gray-750 hover:bg-blue-800 flex gap-1 items-center"
          >
            <FontAwesomeIcon icon={faCheck} />
            <p>Apply</p>
          </button>
          <button
            onClick={clearFilterHandler}
            className="flex gap-1 items-center justify-center cursor-pointer reg-btn-outline-dark mr-1 w-full hover:bg-gray-250 font-semibold rounded-lg mt-2 py-2 text-gray-dark"
          >
            <FontAwesomeIcon icon={faTimes} />
            <p>Clear</p>
          </button>
        </div>
      </div>

      <div className={`mb-3 md:hidden ${'flex justify-between'}`}>
        {<h1 className={`my-3 font-bold text-lg`}>Sport List</h1>}
        <img src={SearchIcon} className="hidden" alt="search icon" />
        <div onClick={filterPopupHandler} className="flex cursor-pointer items-center">
          <img src={FilterIcon} className="cursor-pointer w-5 h-5" alt="filter icon" />
          <p className="text-gray-450 text-sm cursor-pointer">Filters</p>
        </div>
      </div>

      <div className={`md:flex hidden gap-2 justify-between items-center`}>
        {<h1 className="my-3 font-bold text-lg">Sport List</h1>}
        {/* <input
          type="text"
          placeholder="Search Sports"
          className="w-full md:w-60 rounded-lg border-b-2 border-gray-400 focus:outline-none focus:border-gray-500"
          value={searchedSport}
          onChange={(e) => setSearchedSport(e.target.value.toLocaleLowerCase())}
        /> */}
        <div className="w-48">
          <SelectList
            dataCy="sport_selector"
            selectedValue={searchedSport}
            setSelectedValue={setSearchedSport}
            placeholder="Sport"
            listData={getSportsSelectorList()}
            filterClose={true}
            setFilterPopup={() => {}}
          />
        </div>
      </div>
      {/* {console.log(sportData)} */}
      {sportData.isLoading && <CustomLoader />}
      {sportsToShow?.length === 0 ? <div className="text-center w-full my-16"></div> : null}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 mt-10 gap-2 lg:gap-4">
        {sportData.isFetched &&
          sportData.data?.length > 0 &&
          sportsToShow.map((item, index) => (
            <Card
              data={item}
              redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/sports/${
                item.title
              }`}
              key={index}
            />
          ))}
        {/* <ListLoader queryData={sportData} queryKey="title" 
        CustomLoader={CustomLoader}
        >
          {({ item, index }) => {
            return (
              <Card
                data={item}
                redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/sports/${
                  item.title
                }`}
                key={index}
              />
            );
          }}
        </ListLoader> */}
        {
          // sportData.map((el, id) => {
          // {/*  return <Card data={el} key={id} redirectTo={`/micro-site/landing/sports/basketball`} />;*/}
          // {/*})}*/}
        }
      </div>
    </div>
  );
}

const CustomLoader = () => {
  return (
    <div className="w-full flex justify-center min-h-screen mt-4">
      <Loader className={'mt-4'} />
    </div>
  );
};
