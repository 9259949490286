import React from 'react';
import CardDetail from './InformationDetail';
import ContactCard from './ContactCard';
import 'react-quill/dist/quill.core.css';
export default function InformationCard({ title, content, index, len, color, shadow }) {
  return (
    <div className={`py-4 md:py-9 ${!shadow ? '' : 'shadow-shadowBottom'}`}>
      <div className="py-0">
        <div className={'mb-4'}>
          <h1 className={'md:text-4xl text-lg font-bold ' + (color ? `text-${color}` : null)}>
            {title}
          </h1>
          <div className={'w-16 h-0.5 md:h-2 mt-1 md:mt-3 ' + (color ? `bg-${color}` : null)} />
        </div>
        {title !== 'Contact Information' ? (
          <div>
            {content.map((item, index) => (
              <CardDetail key={index} index={index} subContent={item} />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-10 mt-10">
            {content.map((item, index) => (
              <ContactCard key={index} content={item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
