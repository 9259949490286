import LightGallery from 'lightgallery/react';

// If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

import './MediaSlider.css';

// import plugins if you need
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
// import { useMediaDetails } from '../../../queries/hooks'
import DetailItemLoader from '../../../../components/DetailItemLoader';
// import ListLoader from '../../../../components/ListLoader';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

export default function MediaSlider({ data, limit }) {
  return (
    <DetailItemLoader
      queryData={data}
      queryKey={'name'}
      EmptyMessage={'Stay tuned , Images and Videos will be updated shortly'}
      EmptyMessageStyle={{
        position: 'absolute',
        margin: 'auto',
        backgroundColor: '#fff',
        padding: '10px',
      }}
      limit={limit}
    >
      {(item) => {
        console.log('item', item);
        return (
          <div className="">
            <LightGallery
              licenseKey={process.env.REACT_APP_LIGHT_GALLERY_LICENSE_KEY}
              speed={500}
              plugins={[lgVideo]}
              elementClassNames="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-10 mt-4 md:mt-0 relative auto-rows-min"
              download={false}
            >
              {item.data &&
                item.data.map((data, idx) => (
                  <a
                    key={idx}
                    className="cursor-pointer relative card-bg rounded-lg overflow-hidden"
                    href={
                      data.type === 'Image'
                        ? data.image
                        : data.type === 'Video(youtube)'
                        ? `https://www.youtube.com/watch?v=${data.video_id}`
                        : data.type === 'Video(vimeo)'
                        ? `https://vimeo.com/${data.video_id}`
                        : ''
                    }
                  >
                    {console.log(data)}
                    {data.type.toLowerCase().includes('video') && (
                      <div
                        className={
                          'absolute top-0 left-0 h-full w-full flex justify-center items-center opacity-70 rounded-lg'
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlayCircle}
                          className=" text-gray-100 z-3 text-5xl"
                        />
                      </div>
                    )}
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={
                        data.type === 'Image'
                          ? data.image
                          : data.type === 'Video(youtube)'
                          ? `https://ytimg.googleusercontent.com/vi/${data.video_id}/hqdefault.jpg`
                          : data.type === 'Video(vimeo)'
                          ? `https://vumbnail.com/${data.video_id}.jpg`
                          : null
                      }
                      alt=""
                      className="h-full w-full object-cover"
                    />
                    <div className="absolute ml-4 bottom-4">
                      <h3 className="text-white text-xs md:text-lg font-bold mt-1 font-poppins">
                        {data.media_name}
                      </h3>
                      {/* <p className="text-white text-xxs md:text-xs md:mt-1">{item.img_description}</p> */}
                    </div>
                  </a>
                ))}
            </LightGallery>
          </div>
        );
      }}
    </DetailItemLoader>
  );
}
