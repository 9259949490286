import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
const NotFoundPage = ({ title, line1, line2 }) => {
  return (
    <div className="page-404 flex items-center justify-center min-h-screen">
      <div className="px-2">
        <div>
          <div className="flex justify-center flex-col items-center group ">
            <div className="text-6xl md:text-7xl font-bold font-poppins bg-white rounded-full  h-48 w-48 md:h-64 md:w-64 flex justify-center items-center shadow-lg relative">
              <span className="text-gray-200 group-hover:text-blue-950 transition-all duration-300 ease-in-out">
                {title}
              </span>{' '}
              <div className="absolute top-0 -right-12 bg-gray-15 rounded-full p-2 md:p-4 group-hover:bg-blue-950 flex justify-center items-center transition-all duration-300 ease-in-out">
                <FontAwesomeIcon
                  icon={faHome}
                  className="bg-blue-950 text-white  rounded-full md:text-5xl p-4 text-3xl group-hover:bg-gray-15 group-hover:text-blue-950 transition-all duration-300 ease-in-out"
                />
              </div>
            </div>
            <div className="flex flex-col items-center my-4 font-medium font-poppins">
              <span>{line1}</span>
              <span>{line2}</span>
              <Link to="/" className="mt-2 text-blue-400">
                <i></i>&nbsp; Return home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
