import React from 'react';
// import playerImg from '../../../assets/img/tennis_player.png';
// import player2Img from '../../../assets/img/player_2.png';
import { useEventAttractions } from 'micro-site/queries/hooks';

export default function Attractions() {
  const eventAttractionsListQueryData = useEventAttractions({
    tournament_id: '221',
  });

  console.log('eventAttractionsListQueryData', eventAttractionsListQueryData);

  return (
    <div className="px-4 md:px-6 pt-4 pb-14 max-w-fhd mx-auto no-scrollbar">
      <h1 className={'font-bold font-roboto text-2xl mb-4'}>
        WATCH SFA CHAMPIONSHIP UTTARAKHAND 2022 LIVE NOW
      </h1>
      <iframe
        src={'https://green-tomato-llama.builder.live/live-streaming'}
        className={'w-full h-screen no-scrollbar'}
      />
      {/*
      <h3 className="text-lg md:pb-3 md:shadow-shadowBottom md:text-2xl font-bold text-gray-750 mt-2">
        Upcoming Attractions
      </h3>
      <hr className="w-16 mx-0 md:hidden my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      <div className="grid grid-flow-row mt-10 justify-items-stretch md:items-start grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-10">
        <ListLoader queryData={eventAttractionsListQueryData} queryKey="eventAttractions">
          {({ item, index }) => {
            return <AttractionCard key={index} data={item} />;
          }}
        </ListLoader>
      </div>
*/}
    </div>
  );
}
