/**
 * Override Formio configs to take into effect globally across application
 */

import { Formio, Templates } from '@formio/react';
// import tailwind from '@tsed/tailwind-formio';
import Http from 'utils/Http';
Formio.icons = 'fontawesome';
// Formio.use(tailwind);
Templates.framework = 'tailwind';

Formio.setBaseUrl(window.location.origin);

// Formio.request = async (url, method, data, header, opts) => {
//   let newUrl = url;
//   if (url.includes('sfa_online.api_exposed.get_states'))
//     newUrl = 'sfa_online.api_exposed.get_states';
//   else if (url.includes('sfa_online.api_exposed.get_where_did_you_hear_about_us'))
//     newUrl = 'sfa_online.api_exposed.get_where_did_you_hear_about_us';
//   // else if (url.includes('sfa_online.api_exposed.get_goalkeeper'))
//   //   newUrl = 'sfa_online.api_exposed.get_goalkeeper';
//   else newUrl = url;

//   // console.log(newUrl);
//   const res = await Http.fetch({
//     apiEndpoint: newUrl,
//   });
//   return res.message;
// };

const customProvider = {
  customBase64: function customBase64() {
    return {
      title: 'CustomBase64',
      name: 'customBase64',
      uploadFile: async (file, fileName) => {
        const res = await Http.uploadFile({
          apiEndpoint: 'upload_file',
          name: fileName,
          file,
        });

        return res.message ? res.message : 'Something went wrong';
      },
      downloadFile(file) {
        // Return the original as there is nothing to do.
        return Promise.resolve(file);
      },
    };
  },
};

Formio.Providers.addProviders('storage', customProvider);
