import React from 'react';
import Loader from '../Loader';

const ListLoader = ({
  queryData,
  queryKey,
  EmptyMessage,
  EmptyMessageStyle,
  loaderSize,
  CustomLoader,
  showLimited,
  limit,
  isInfinite,
  isInfiniteFetching,
  ...props
}) => {
  if (queryData.isLoading)
    return (
      <div className="flex w-full justify-center bg-white items-center">
        {CustomLoader ? <CustomLoader /> : <Loader loaderSize={loaderSize} />}
      </div>
    );
  if (queryData.isError) return <h1>Error</h1>;
  if (!props.children) return <h1>This component does not have renderable child </h1>;
  if (!queryKey) return <h1>DEV ERROR: queryKey not provided in props</h1>;
  if (!isInfinite && !Array.isArray(queryData.data))
    return <h1>DEV ERROR: QueryData does not return array</h1>;
  if (isInfinite && !Array.isArray(queryData?.data?.pages?.at(0)?.data))
    if (queryData.data.length === 0 || queryData?.data?.pages?.at(0)?.data)
      return EmptyMessage ? (
        <>
          {' '}
          <p
            className={`w-full text-center my-20 text-sm text-gray-750 `}
            style={EmptyMessageStyle}
          >
            {EmptyMessage}
          </p>
        </>
      ) : (
        <h1 data-cy="data_empty" className="w-full text-center my-20 text-sm text-gray-750">
          Data is empty
        </h1>
      );
  if (isInfinite) {
    return (
      <>
        {queryData.data.pages.map((page, i) =>
          page.data.map((datum, i) =>
            !showLimited ? (
              <props.children key={i} item={datum} index={i} data={datum} />
            ) : (
              i < (limit || 10) && <props.children key={i} item={datum} index={i} data={datum} />
            )
          )
        )}
        {isInfinite && isInfiniteFetching && (
          <div className="flex justify-center">
            <Loader width={8} height={8}></Loader>
          </div>
        )}
      </>
    );
  }
  return queryData.data.map((datum, i) =>
    !showLimited ? (
      <props.children key={i} item={datum} index={i} data={datum} />
    ) : (
      i < (limit || 10) && <props.children key={i} item={datum} index={i} data={datum} />
    )
  );
};
export default ListLoader;
